<template>
    <div class="BigBox">
        <div class="TopBox">
            <div class="Back">
                <img @click="$router.push('/')" src="../../assets/CulturalHomePage/PomePage.png" alt="" style="height: 100%; cursor: pointer; margin-top: 1.5vh" />
            </div>
            <div class="User">
                <img @click="IntanPerson()" src="../../assets/CulturalHomePage/personal.png" alt="" style="height: 100%; cursor: pointer; margin-top: 1.5vh" />
            </div>
            <div class="TopTitle">数字博物馆</div>

        </div>
        <div class="MuseumBox">
            <div class="swiper-container swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide to" v-for="(item, index) in museumList" :key="index" @click="toMuseun(item,index)">
                        <div>
                            <span>{{ item.name }}博物馆</span>
                        </div>
                        <div class="SwiperImg">
                            <img :src="item.img" />
                        </div>
                        <div style="width: 4rem;height: 4rem;" v-if="index==active">
                            <img class="BottomJianTou" src="../../assets/DigitalMuseum/down.png" style="width: 100%;height: 70%;">
                        </div>
                        <div style="color: rgb(249,187,2);font-size: 3vh;position: relative;top: -2vh;" v-if="index==active">
                            <span>去游览</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TiShiBox ref="childRef"></TiShiBox>
    </div>
</template>
<script>
import $ from 'jquery'
import TiShiBox from '../Public/TiShiBox.vue'
export default {
    data () {
        return {
            museumList: [
                {
                    name: '靖港古镇',
                    img: require('@/assets/DigitalMuseum/boat.png'),
                },
                {
                    name: '铜官古镇',
                    img: require('@/assets/DigitalMuseum/bar.png'),
                },
                {
                    name: '新康古镇',
                    img: require('@/assets/DigitalMuseum/mask.png'),
                },
                {
                    name: '乔口古镇',
                    img: require('@/assets/DigitalMuseum/fish.png'),
                },
                {
                    name: '书堂山古镇',
                    img: require('@/assets/DigitalMuseum/pen.png'),
                },
            ],
            active: 0,
        }
    },
    mounted () {
        this.initSwiper()
    },
    components: {
        TiShiBox
    },
    methods: {
        initSwiper () {
            setTimeout(() => {
                var swiper = new Swiper('.swiper', {
                    effect: 'coverflow',
                    // loop: true,
                    grabCursor: true,
                    centeredSlides: true,
                    slidesPerView: 3,
                    initialSlide: 2,
                    paginationClickable: true,
                    loopedSlides: 2,
                    slideToClickedSlide: true,

                    // 前，后进按钮
                    prevButton: '.swiper-button-prev',
                    nextButton: '.swiper-button-next',
                    pagination: {
                        el: ' .swiper-pagination .swiper',
                    },
                    coverflowEffect: {
                        rotate: -20,
                        stretch: -5,
                        depth: 80,
                        modifier: 2,
                        slideShadows: false,
                    },
                    // 监听哪个在中间
                    on: {
                        slideChange: (swiperHere) => {
                            this.active = swiperHere.activeIndex
                        }
                    },
                })
            }, 300)
        },
        toMuseun (data, idx) {
            if (this.active != idx) return
            if (data.name == '铜官古镇') {
                this.$router.push('/digtalMuseum')
            } else {
                this.$refs.childRef.ChuFa()
            }
        },
        IntanPerson () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get('token') == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
    },
}
</script>
<style lang="less" scoped>
.BigBox {
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/HomePage/HomePageTwo/HomepageBG.png');
    background-size: 100% 100%;
    background-color: #010e30;
    .TopBox {
        height: 10%;
        position: relative;
        .TopTitle {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(215, 238, 253);
            font-size: 5vh;
            font-family: 'YouShe';
            letter-spacing: 0.4rem;
        }
        .Back {
            position: absolute;
            // height: 9vh;
            width: 7.5vh;
            height: 7vh;
            left: 10%;
            top: 10%;
        }
        .User {
            position: absolute;
            width: 7.5vh;
            height: 7vh;
            right: 10%;
            top: 10%;
        }
    }
    .MuseumBox {
        width: 80%;
        margin: 5% auto;
        height: 50%;
        color: #fff;
        .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
                height: 100% !important;
                .swiper-slide {
                    background-position: center;
                    width: 28%;
                    height: 100%;
                    background: url(../../assets/DigitalMuseum/SwiperItemBG.png);
                    background-size: 100% 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    color: #7d97f1;
                    font-size: 1.125rem;
                    padding: 2rem 0;
                    box-sizing: border-box;
                    .SwiperImg {
                        height: 65%;
                        display: flex;
                        align-items: center;
                        margin-top: 5%;
                        img {
                            height: 100%;
                        }
                    }
                    .BottomJianTou {
                        position: relative;
                        animation: BottomJianTou 2s infinite; // 反反向再运行一遍
                        animation-direction: alternate;
                        cursor: pointer;
                    }
                    @keyframes BottomJianTou {
                        0% {
                            top: -0.5rem;
                        }

                        100% {
                            top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
</style>